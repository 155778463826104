import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tasking-request-status"
    }}>{`Tasking Request Status`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`You can monitor the status of your tasking request at any time after submission. Tasking status requests will return information on the tasking request itself as well as the current and previous statuses of the tasking request. Tasking request statuses include the status name, status description, and the time of the status update. `}</p>
    <h2 {...{
      "id": "status-descriptions"
    }}>{`Status Descriptions`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Received`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request has been input into the system for cost estimation. At this stage it has not been submitted for scheduling.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Review`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request has been tessellated, at least some collection opportunities have been found. Cost estimate is ready. Waiting for user review and approval for tasking.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Submitted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer has approved the cost submitted request for scheduling. Request queue for processing in the scheduling system and is waiting to be scheduled or rejected by Capella.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request is being scheduled, and collections are being taken but we cannot guarantee full completion at this time. At this stage, the tasking system will return a `}<a parentName="td" {...{
              "href": "https://docs.capellaspace.com/constellation-tasking/tasking-request-status#potentially-conflicting-tasks"
            }}>{`list of potentially conflicting tasks`}</a>{` from within the user's organization that may cause the new tasking request to be rejected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Accepted`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request is guaranteed for full collection.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Rejected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request cannot be completed. Tasks can be `}<a parentName="td" {...{
              "href": "https://docs.capellaspace.com/constellation-tasking/tasking-request-status#tasking-request-rejected-due-to-conflicting-tasks"
            }}>{`rejected due to conflicting tasks`}</a>{` from within a user's organization.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Expired`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`We have been able to determine that by the end of the request we will not be able to fully collect the AOI.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Completed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Request has been finished. All required collects have been delivered.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Canceled`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Customer has cancelled the request. It will not be collected.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An error occurred during processing and ingestion of the request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Anomaly`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An anomaly occurred during collect that prevents full completion of the tasking request.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Retrying`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The tasking request is being reattempted following an anomaly.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "getting-the-current-status-of-a-request"
    }}>{`Getting the Current Status of a Request`}</h2>
    <h3 {...{
      "id": "single-tasking-request"
    }}>{`Single Tasking Request`}</h3>
    <p>{`To get the status of your single tasking request, all you need is the identifier of the tasking request of interest (`}<inlineCode parentName="p">{`taskingrequestId`}</inlineCode>{`):`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/task/{{taskingrequestId}}
`}</code></pre>
    <p>{`In the response, you will find a `}<inlineCode parentName="p">{`statusHistory`}</inlineCode>{` property, which will contain a list of the current and historical statuses of the request. The top item will be the current status of the request. Alternatively, you can search or sort for the item with the most recent `}<inlineCode parentName="p">{`time`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`"statusHistory": [
  {
    "time": "2022-12-05T15:12:00.811Z",
    "code": "completed",
    "message": "Tasking request has been completed."
  },
  {
    "time": "2022-12-05T15:06:00.321Z",
    "code": "accepted",
    "message": "Request can be completely satisfied during validity window."
  },
  {
    "time": "2022-12-05T15:05:00.264Z",
    "code": "active",
    "message": "Collections will begin but full coverage cannot be guaranteed at this time."
  },
  {
    "time": "2022-12-05T15:04:31.558Z",
    "code": "submitted",
    "message": "Customer has approved the tasking request."
  },
  {
    "time": "2022-12-05T14:33:25.394Z",
    "code": "review",
    "message": "Tasking request ready for review."
  },
  {
    "time": "2022-12-05T14:32:05.847Z",
    "code": "received",
    "message": "Request created"
  }
]
`}</code></pre>
    <h3 {...{
      "id": "repeat-tasking-request"
    }}>{`Repeat Tasking Request`}</h3>
    <p>{`To get the status of your repeat tasking request, all you need is the identifier of the repeat request of interest (`}<inlineCode parentName="p">{`repeatrequestId`}</inlineCode>{`):`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/repeat-requests/{{repeatrequestId}}
`}</code></pre>
    <p>{`In the response, you will find a `}<inlineCode parentName="p">{`statusHistory`}</inlineCode>{` property, which will contain a list of the current and historical statuses of the request. The top item will be the current status of the request. Alternatively, you can search or sort for the item with the most recent `}<inlineCode parentName="p">{`time`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{}}>{`"statusHistory": [
  {
    "time": "2022-12-20T23:00:15.689Z",
    "code": "active",
    "message": "Repeat series will begin generation."
  },
  {
    "time": "2022-12-20T22:58:35.046Z",
    "code": "submitted",
    "message": "Submitted for scheduling."
  },
  {
    "time": "2022-12-20T22:03:27.732Z",
    "code": "review",
    "message": "Repeat request ready for review."
  },
  {
    "time": "2022-12-20T22:03:26.329Z",
    "code": "received",
    "message": "Request created"
  }
]
`}</code></pre>
    <h2 {...{
      "id": "potentially-conflicting-tasks"
    }}>{`Potentially Conflicting Tasks`}</h2>
    <p>{`Tasking requests with an `}<inlineCode parentName="p">{`Active`}</inlineCode>{` status are awaiting scheduling by Capella’s tasking system or, in the case of repeat and area tasks, are in the process of collection but have not yet finished all the required collects to move into the `}<inlineCode parentName="p">{`Completed`}</inlineCode>{` status. These requests may go to `}<inlineCode parentName="p">{`Accepted`}</inlineCode>{` or `}<inlineCode parentName="p">{`Rejected`}</inlineCode>{` once the Scheduler has finished evaluating their feasibility given the imaging constraints of the request and the available constellation capacity. `}</p>
    <p>{`While tasks are in the `}<inlineCode parentName="p">{`Active`}</inlineCode>{` status, a list of other tasking requests within an organization that may potentially led to the task being `}<inlineCode parentName="p">{`Rejected`}</inlineCode>{` is available to aid users in their prioritization process. This list is available when a tasking request has a status of `}<inlineCode parentName="p">{`Active`}</inlineCode>{` with the additional message of `}<inlineCode parentName="p">{`Collection will be attempted but conflicting tasks may prevent task completion`}</inlineCode>{`.`}</p>
    <p>{`When checking the status of a tasking request using the GET `}<inlineCode parentName="p">{`/task`}</inlineCode>{` or GET `}<inlineCode parentName="p">{`/tasks/paged/`}</inlineCode>{` API endpoints, the status history will return this unique message.`}</p>
    <pre><code parentName="pre" {...{}}>{`"statusHistory": [   
    {   
      "time": "2024-02-27T03:18:28.00Z",
      "code": "active",  
      "message": "Collection will be attempted but conflicting tasks may prevent task completion." 
    }  
  ],
`}</code></pre>
    <p>{`An individual `}<inlineCode parentName="p">{`taskingRequestId`}</inlineCode>{` with the above message can then be submitted to the GET `}<inlineCode parentName="p">{`/task/{taskingrequestId}`}</inlineCode>{` endpoint. This returns information about each conflicting task that may prevent completion of the new tasking request:`}</p>
    <pre><code parentName="pre" {...{}}>{`"conflictingTasks": [  
    {  
      "taskingrequestId": "abcd1234-abcd-1234-abcd-1234abcd1234",  
      "taskingrequestName": "Request name here",   
      "repeatrequestId": "zyxw0987-zyxw-0987-zyxw-0987zyxw0987",  
      "collectionTier": "urgent",   
      "windowOpen": "2024-02-262T08:00:04.053000",   
      "windowClose": "2024-02-26T08:00:27.185000",   
    },  
    {  
      "taskingrequestId": "efgh5678- efgh-5678-efgh-5678efgh5678",  
      "taskingrequestName": "Request name here",   
      "repeatrequestId": "",  
      "collectionTier": "priority ",   
      "windowOpen": "2024-02-26T08:00:04.053000",   
      "windowClose": "2024-02-29T08:00:27.185000",   
    }   
  ],
`}</code></pre>
    <p>{`Notification about the existence of conflicting tasks is limited to your organization only. No task information is available to other Capella Space customers, even if your task conflicts with theirs or vice versa. Please note, a user will see basic information about all the conflicting tasks within their organization even if they are not the submitting user.
If no conflicting tasks from within your organization exist,the API will instead return a value of null in the `}<inlineCode parentName="p">{`conflictingTasks`}</inlineCode>{` field. `}</p>
    <pre><code parentName="pre" {...{}}>{`"statusHistory": [   
    {   
      "time": "2024-02-27T03:18:28.00Z",   
      "code": "active",  
      "message": "Collections will begin but full coverage cannot be guaranteed at this time.",  
    }  
  ],  
  “conflictingTasks”: null,
`}</code></pre>
    <p>{`The identification of conflicting tasks for a tasking request with the status of `}<inlineCode parentName="p">{`Active`}</inlineCode>{` does not guarantee the tasking request will be rejected nor does the lack of conflicting tasks guarantee acceptance. A tasking request submitted at the `}<inlineCode parentName="p">{`Flexible`}</inlineCode>{` tier, for example, may go to `}<inlineCode parentName="p">{`Active`}</inlineCode>{` with a number of conflicting tasks yet the ability of the Scheduler to continually shuttle the request until the `}<inlineCode parentName="p">{`windowClose`}</inlineCode>{` date could allow it to still be scheduled and collected. Only when a task moves to the status of `}<inlineCode parentName="p">{`Rejected`}</inlineCode>{` with the message `}<inlineCode parentName="p">{`The tasking system is unable to schedule the tasking request due to conflicting tasks`}</inlineCode>{` have the conflicting tasks prevented scheduling of the tasking request.`}</p>
    <h2 {...{
      "id": "tasking-request-rejected-due-to-conflicting-tasks"
    }}>{`Tasking Request Rejected Due to Conflicting Tasks`}</h2>
    <p>{`Tasking requests can be rejected because the new request conflicts with one or more of your organization’s currently `}<inlineCode parentName="p">{`accepted`}</inlineCode>{` tasks. If a tasking request is rejected due to conflicting tasks, the task status will be `}<inlineCode parentName="p">{`Rejected`}</inlineCode>{` with the additional message that `}<inlineCode parentName="p">{`The tasking system is unable to schedule the tasking request due to conflicting tasks`}</inlineCode>{`.`}</p>
    <p>{`Conflicting tasks information is generated for single tasking requests and individual tasking requests generated by a repeat series. Basic information about the conflicting tasks will also be returned in order to help you decide if you want to cancel an existing task(s) to make room in the Scheduler for the new tasking request or resubmit the task with different parameters. See the `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/constellation-tasking/tasking-request-status#acting-on-conflicting-task-information"
      }}>{`Acting on Conflicting Tasks`}</a>{` section below for more information. `}</p>
    <p>{`When checking the status of a tasking request using the GET `}<inlineCode parentName="p">{`/task`}</inlineCode>{` or GET `}<inlineCode parentName="p">{`/tasks/paged/`}</inlineCode>{` API endpoints, the status history messages for display this unique rejection message.`}</p>
    <pre><code parentName="pre" {...{}}>{`  "statusHistory": [   
    {   
      "time": "2022-11-22T16:25:49.340Z",
      "code": "rejected",  
      "message": "The tasking system is unable to schedule the tasking request due to conflicting tasks." 
    }  
  ],  
`}</code></pre>
    <p>{`An individual `}<inlineCode parentName="p">{`taskingRequestId`}</inlineCode>{` with the above error message can then be submitted to the GET `}<inlineCode parentName="p">{`/task/{taskingrequestId}`}</inlineCode>{` endpoint. This returns information about each conflicting task that prevents acceptance of the new tasking request:`}</p>
    <pre><code parentName="pre" {...{}}>{`  "conflictingTasks": [  
    {  
      "taskingrequestId": "abcd1234-abcd-1234-abcd-1234abcd1234",  
      "taskingrequestName": "Request name here",   
      "repeatrequestId": "zyxw0987-zyxw-0987-zyxw-0987zyxw0987",  
      "collectionTier": "urgent",   
      "windowOpen": "2022-11-02T08:00:04.053000",   
      "windowClose": "2022-11-02T08:00:27.185000",   
    },  
    {  
      "taskingrequestId": "efgh5678- efgh-5678-efgh-5678efgh5678",  
      "taskingrequestName": "Request name here",   
      "repeatrequestId": "",  
      "collectionTier": "priority ",   
      "windowOpen": "2022-11-02T08:00:04.053000",   
      "windowClose": "2022-11-05T08:00:27.185000",   
    }   
  ],  
`}</code></pre>
    <p>{`Notification about the existence of conflicting tasks is limited to your organization only. No task information is available to other Capella Space customers, even if your task conflicts with theirs or vice versa. Please note, a user will see basic information about all the conflicting tasks within their organization even if they are not the submitting user.`}</p>
    <p>{`If no conflicting tasks from within your organization exist, a tasking request may still be rejected. The API will instead return a value of null in conflictingTasks field. `}</p>
    <pre><code parentName="pre" {...{}}>{`  "statusHistory": [   
    {   
      "time": "2022-11-22T16:25:49.340Z",   
      "code": "rejected",  
      "message": "Rejection reason here",  
    }  
  ],  
  “conflictingTasks”: null,
`}</code></pre>
    <h3 {...{
      "id": "acting-on-conflicting-task-information"
    }}>{`Acting on Conflicting Task Information`}</h3>
    <p>{`When a tasking request is rejected due to conflicting tasks, you can decide to cancel a conflicting task using the `}<inlineCode parentName="p">{`taskingRequestId`}</inlineCode>{` to make room in the schedule for your new tasking request. This can be done by submitting a `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/constellation-tasking/cancel-task"
      }}>{`PATCH request to cancel the tasking request`}</a>{`. Once canceled, you can resubmit the rejected task. Tasking requests can be canceled up to 3 hours prior to the scheduled collection time. `}</p>
    <p>{`The cancellation of a conflicting tasks and submission of a new tasking request should be done in the same 15-minute window to ensure the Scheduler picks up both requests in tandem. The Scheduler update runs at 00, 15, 30, and 45 minutes past the hour and updates based on information submitted within the following windows: 00:00-00:15, 00:15-00:30, 00:30-00:45, 00:45-00:60.`}</p>
    <p>{`Cancellation of a previously accepted task to accommodate a new tasking request does not guarantee the new request will be schedule. For example, another customer’s tasking request may still be slotted in ahead of your new request(s) due to an earlier submission datetime, a higher collection tier, or a shorter duration window. If the canceled task is still needed, it is best to resubmit the canceled task with a `}<inlineCode parentName="p">{`colectionTier`}</inlineCode>{` of lower importance and/or with a longer `}<inlineCode parentName="p">{`windowOpen`}</inlineCode>{` and `}<inlineCode parentName="p">{`windowClose`}</inlineCode>{`than the task of higher importance.`}</p>
    <p>{`Please note, if the conflicting task is part of an area (polygon-based) task that has already started collecting, the conflicting task cannot be cancelled. A child task from a repeat task series or the entire series can be cancelled. It is not recommended to cancel the entire repeat task series.`}</p>
    <p>{`Another option is to use the information about the conflicting task(s) to change parameters of the rejected task such as the `}<inlineCode parentName="p">{`collectionTier`}</inlineCode>{` or `}<inlineCode parentName="p">{`windowClose`}</inlineCode>{` and resubmit to see if the scheduling system accepts the new tasking request. For rapid resubmission, you should use the `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/constellation-tasking/tasking-requests#submitting-an-existing-tasking-request-or-collect-for-retasking"
      }}>{`Retask endpoints`}</a>{`.`}</p>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on constellation tasking requests, see the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/tasking"
      }}>{`Tasking`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      